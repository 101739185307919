module.exports = {
	colors: {
		main: '#7f278d',
		sec: '#b6c842',
		alt: '#7f278d',
	},

	showFunds: false,

	showBuses: false,
		
	showHomeworks: true,

	about: {
		branches: [
			{
				name: 'مركز جيل الشهباء',
				location: {
					name: 'الفرقان - شارع الحرم الجامعي - مقابل كلية الاقتصاد',
					url: 'https://maps.app.goo.gl/idVjp1TpdvfQQxYC8',
				},
				numbers: [
					{ name: 'هاتف الارضي', phone: '0212640070' },
					{ name: 'الاستقبال', phone: '0989557706' },
				],
			},
		],
		mobiles: ['0989557706'],
		phones: ['0212640070'],
		address: 'الفرقان - شارع الحرم الجامعي - مقابل كلية الاقتصاد',
		data: [0, 0],
		email: null,
		facebook: 'https://www.facebook.com/share/xg4xK5MxbcUosKnF/?mibextid=qi2Omg',
		privacy: 'https://gelalshahbaa.edunixsystem.com/privacy',
		details: [
			{
				title: 'عن المنشاة',
				content: [
					'من  منطلقنا بناء الأوطان  يبدأ ببناء الإنسان ...',
					'مركز للدورات التعليمية  لشهادة التعليم الثانوي  و دورات مواد لتحضير للمرحلة الثانوية بالفرع العلمي .',
					'يمتاز معهدنا بإهتمامه الفائق بطلابه فرداَ فرداَ وبأساليبه التعليمية المتطورة والمعاصرة والإضاءة على قدراتهم الدراسية والإبداعية ..',
					'أما عن كادرنا فهو من نخبة الأساتذة في حلب  فقد تم انتقائه بعناية فإن لكل معلم بصمة خاصة تزيد النخبة بريقاَ وهو الكادر الأول الذي يتابع الطالب بحالته النفسية والدراسية والمزاجية وذلك يكون بتنظيم أوقات دراستهم و تدريبهم و تحديد أولوياتهم في الدراسة .',
					'للنخبة تاريخ عريق مكلل بالنجاحات والأرقام القياسية فنحن على موعد سنوي ثابت مع طلاب الشهادة بمجاميع كاملة ومراتبهم الأولى على القطر .',
					'تعمل أسرة النخبة التعليمية على التطور دائماَ ولا تأبه لأي عائق يواجهها أو مشكلة تعترض طريقها وعلى هذا المنوال وهذه الخطوات تكمل النخبة بريقها ..'

				],
			},
		],
	},

	pwa: {
		name: 'مركز جيل الشهباء',
		shortName: 'مركز جيل الشهباء',
		description: 'تطبيق مركز جيل الشهباء التعليمي الخاص',
	},
};
