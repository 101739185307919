import { notificationTypes } from '@/helpers/enums';
import store from '@/store';

export const notificationOptions = {
	icon: '/logo.png',
	dir: 'rtl',
	lang: 'ar-SA',
};

export const notificationPageRedirect = [
	{ type: notificationTypes.message, redirect: '/chat', routeName: 'chat' },
	{ type: notificationTypes.attendance, redirect: '/attendance', routeName: 'attendance' },
	{ type: notificationTypes.newMark, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.newMarks, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.schedule, redirect: '/schedule', routeName: 'schedule' },
	{ type: notificationTypes.newTest, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.testsSchedule, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.transport, redirect: '/', routeName: 'home' },
	{ type: notificationTypes.notes, redirect: '/behavior', routeName: 'behavior' },
];

export const firebase = {
	configs: {
		apiKey: 'AIzaSyCpwa-DAUD3_nCEpGJMoZ6YbSJBKy7uXd0',
		authDomain: 'massar-academy-app.firebaseapp.com',
		projectId: 'massar-academy-app',
		storageBucket: 'massar-academy-app.firebasestorage.app',
		messagingSenderId: '363398407057',
		appId: '1:363398407057:web:9b8a026d22b1e9b7bd3030'
	},
	publicKey: 'BHKPQpw6526-y4OV84CgyfpGNTiK9-XK8yfzqI9-lkhjRiBGBEXfYmg8O-bHZvIjMjMJkJOvbt4fyTkSZdHCt5w',
};

export const onGetNotification = () => {
	store.dispatch('notification/fetchAll');
};
